<template>
  <div class="w-100">
    <h5 class="mb-0">{{ $tc('notification', 2) | toCapitalize }}</h5>
    <hr>
    <b-table striped bordered hover :items="notifications" :fields="columns">
      <div slot="order" slot-scope="row" class="text-right">{{ row.index + 1 }}</div>
      <div slot="name" slot-scope="row">{{ row.item.name }}</div>
      <template slot="actions" slot-scope="row">
        <div class="w-100 text-center">
          <b-button class="mx-2" @click="show(row.item)"><i class="fa fa-eye"></i></b-button>
          <b-button variant="danger" class="mx-2" @click="destroy(row.item.id)"><i class="fa fa-trash px-1"></i></b-button>
        </div>
      </template>
    </b-table>
    <b-modal ref="modalDetail" :title="notification.subject" centered>
      <p>{{ $t('from') }} <strong>{{ notification.sender }}</strong></p>
      <p>{{ $t('message') }} <strong>{{ notification.message }}</strong></p>
      <hr>
      <span class="text-muted">{{ $t('date') }}: {{ notification.created_at }}</span>
      <template slot="modal-footer">
        <b-button type="button" @click="$refs.modalDetail.hide()">{{ $t('close') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      notifications: [],
      notification: {},
      columns: [
        { key: 'order', label: '#', sortable: false },
        { key: 'sender', label: this.$t('from'), sortable: false },
        { key: 'subject', label: this.$t('subject'), sortable: false },
        { key: 'created_at', label: this.$t('date'), sortable: false },
        { key: 'actions', label: '', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'team'
    ])
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    destroy (notificationId) {
      const path = `auth/${this.lang}/${this.console}/users/notifications/${notificationId}/delete`
      this.$axios.delete(path).then(() => {
        this.$toastr.success(this.$t('updatedInfo'))
        this.notifications = this.notifications.filter(not => {
          return not.id !== notificationId
        })
      })
    },
    show (notification) {
      this.notification = notification
      this.$refs.modalDetail.show()
      if (!notification.viewed) {
        const path = `auth/${this.lang}/${this.console}/users/notifications/${this.notification.id}/show`
        this.$axios.post(path).then(() => {
          this.notifications = this.notifications.map(not => {
            if (not.id === this.notification.id) {
              not.viewed = true
              not._rowVariant = ''
            }
            return not
          })
        })
      }
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/users/notifications`
      this.$axios.get(path).then(response => {
        this.notifications = response.data.data.map(not => {
          if (not.viewed === true) {
            not._rowVariant = ''
          } else {
            not._rowVariant = 'info'
          }
          return not
        })
      })
    }
  }
}
</script>
